import React from "react";
import { Link } from "react-router-dom";

export default function SidebarMenu() {
  return (
    <nav
      id="sidebarMenu"
      className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
    >
      <div className="position-sticky pt-3">
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link
              to="/webutils"
              className="nav-link active"
              aria-current="page"
            >
              <span data-feather="home" className="align-text-bottom"></span>
              Web Utilities
            </Link>
          </li>
          <li className="nav-item">
            <div className="dropdown nav-link dropend">
              <div
                className="dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Company Profile
              </div>
              <ul className="dropdown-menu">
                <li className="w-100">
                  <Link to="/companyprofiles" className="dropdown-item">
                    Profiles
                  </Link>
                </li>
                <li>
                  <Link to="/misi" className="dropdown-item">
                    Misi
                  </Link>
                </li>
                <li>
                  <Link to="/strukturorg" className="dropdown-item">
                    Struktur Organisasi
                  </Link>
                </li>
                <li>
                  <Link to="/value" className="dropdown-item" href="#">
                    Core Values
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <Link to="/gallery" className="nav-link">
              <span
                data-feather="shopping-cart"
                className="align-text-bottom"
              ></span>
              Gallery
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/kelas" className="nav-link">
              <span data-feather="users" className="align-text-bottom"></span>
              Kelas
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/layanan" className="nav-link">
              <span
                data-feather="bar-chart-2"
                className="align-text-bottom"
              ></span>
              Layanan
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/mading" className="nav-link">
              <span data-feather="layers" className="align-text-bottom"></span>
              Mading
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/team" className="nav-link">
              <span data-feather="layers" className="align-text-bottom"></span>
              Team
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/pelatihan" className="nav-link">
              <span data-feather="layers" className="align-text-bottom"></span>
              Pelatihan
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}
