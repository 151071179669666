import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import data from "../Config/dataConfig";
import Header from "../Components/Header";
import SidebarMenu from "../Components/SidebarMenu";
import StrukOrgInput from "../MainPages/StrukOrgInput";
import ModalDeleteItem from "../Components/ModalDeleteItem";
import { deleteMember, getActiveMembers } from "../services/strukturOrgService";
import "../Styles/StrukturOrg.css";

export default function StrukturOrg({ userStatus, uid }) {
  const [members, setMembers] = useState([]);
  const [editMember, setEditMember] = useState({});
  const navigate = useNavigate();

  const getMembers = async () => {
    const { data } = await getActiveMembers();
    setMembers(data.results);
  };

  const rubahMember = (member) => {
    window.scrollTo(0, 0);
    setEditMember(member);
  };

  const hapusMember = async (id) => {
    const headers = { headers: { "x-auth-token": uid } };

    await deleteMember(id, data, headers);
    setEditMember({ id: 0, name: "", photo: data.fotoDefault, jabatan: "" });
    toast("Data sudah dihapus");
    getMembers();
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!userStatus) return navigate("/login");
    getMembers();
  }, [userStatus, navigate]);
  return (
    <div>
      <Header />

      <div className="container-fluid">
        <div className="row vh-100">
          <SidebarMenu />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className=" pt-3 pb-2 mb-3 border-bottom">
              <div className=" pt-3 pb-2 mb-3 border-bottom">
                <StrukOrgInput
                  editMember={editMember}
                  whenSubmit={getMembers}
                  uid={uid}
                />
              </div>
              <div className="table-responsive">
                <h3>Struktur Organisasi</h3>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th className="text-center">Nama</th>
                      <th className="text-center">Jabatan</th>
                      <th className="text-center">Foto</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {members.map((member, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>{member.name}</td>
                          <td>{member.jabatan}</td>
                          <td>
                            <img
                              src={member.photo}
                              className="photoStrukOrg"
                              alt="BTC"
                            />
                          </td>
                          <td>
                            <div className="btn-group">
                              <button
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => rubahMember(member)}
                              >
                                <FontAwesomeIcon icon={faPencil} />
                              </button>
                              <button
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => rubahMember(member)}
                                data-bs-toggle="modal"
                                data-bs-target="#deleteStrukturOrg"
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </div>
                            <ModalDeleteItem
                              id="deleteStrukturOrg"
                              body="Yakin ingin menghapus anggota struktur organisasi ini?"
                              onClick={() => hapusMember(editMember.id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
