import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPencil,
  faPlus,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Header from "../Components/Header";
import SidebarMenu from "../Components/SidebarMenu";
import ModalDeleteItem from "../Components/ModalDeleteItem";
import {
  deleteSingleMading,
  getAllMadings,
  getMadingCategory,
  publishSingleMading,
} from "../services/madingService";

export default function Mading({ userStatus, uid }) {
  const [madings, setMadings] = useState([]);
  const [madingSingle, setMadingSingle] = useState({});
  const [kategoris, setKategoris] = useState([]);
  const navigate = useNavigate();

  const getMadings = async () => {
    const { data } = await getAllMadings();
    setMadings(data.results);
  };

  const getSingleMading = (singleMading) => {
    setMadingSingle(singleMading);
  };

  const hapusMading = async (id) => {
    const data = {};
    const headers = { headers: { "x-auth-token": uid } };
    await deleteSingleMading(id, data, headers);
    toast("Madingnya sudah dihapus");
    getMadings();
  };

  const publishMading = async (id) => {
    const data = {};
    const headers = { headers: { "x-auth-token": uid } };
    await publishSingleMading(id, data, headers);
    toast("Madingnya sudah dipublish");
    getMadings();
  };

  const getKategoriMading = async () => {
    const { data } = await getMadingCategory();
    setKategoris(data.results);
  };

  useEffect(() => {
    if (!userStatus) return navigate("/login");
    getKategoriMading();
    getMadings();
  }, [userStatus, navigate]);

  return (
    <div>
      <Header />

      <div className="container-fluid">
        <div className="row vh-100">
          <SidebarMenu />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 my-3">
            <div className="d-flex justify-content-between">
              <h3>Mading Brilliant</h3>
              <button
                className="btn btn-outline-primary btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#tambahMading"
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
              <div
                className="modal fade"
                id="tambahMading"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <p className="border-bottom">
                        Cek kategori Mading dulu ya
                      </p>
                      <ul>
                        {kategoris.length === 0 ? (
                          <p>Belum ada kategori mading nih</p>
                        ) : (
                          kategoris.map((kategoriSingle) => {
                            return (
                              <li key={kategoriSingle.id}>
                                {kategoriSingle.kategori}
                              </li>
                            );
                          })
                        )}
                      </ul>
                    </div>
                    <div className="modal-footer">
                      <Link
                        to={{
                          pathname: "/mading/tambah",
                        }}
                      >
                        <button
                          className="btn btn-primary btn-sm"
                          data-bs-dismiss="modal"
                        >
                          Ada
                        </button>
                      </Link>
                      <Link
                        to={{
                          pathname: "/mading/kategori/tambah",
                        }}
                      >
                        <button
                          className="btn btn-danger btn-sm"
                          data-bs-dismiss="modal"
                        >
                          Tidak Ada
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Judul</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {madings.length === 0 ? (
                    <tr>
                      <td colSpan={4} className="text-center">
                        Belum ada mading yang dibuat
                      </td>
                    </tr>
                  ) : (
                    madings.map((singleMading, index) => {
                      let kelasPublish = false;
                      kelasPublish =
                        singleMading.status === "deleted" ? true : false;

                      let kelasBadge = "badge";
                      if (singleMading.status === "saved") {
                        kelasBadge += " text-bg-primary";
                      }
                      if (singleMading.status === "published") {
                        kelasBadge += " text-bg-success";
                      } else {
                        kelasBadge += " text-bg-danger";
                      }
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{singleMading.title}</td>
                          <td>
                            <span className={kelasBadge}>
                              {singleMading.status}
                            </span>
                          </td>
                          <td>
                            <div className="">
                              <Link to="/mading/preview" state={singleMading}>
                                <button
                                  className="btn btn-outline-primary btn-sm me-1"
                                  title="See real page"
                                >
                                  <FontAwesomeIcon icon={faEye} />
                                </button>
                              </Link>
                              <Link
                                to={"/mading/edit/" + singleMading.id}
                                state={singleMading}
                              >
                                <button
                                  className="btn btn-outline-primary btn-sm me-1"
                                  title="Edit mading"
                                >
                                  <FontAwesomeIcon icon={faPencil} />
                                </button>
                              </Link>
                              <button
                                className="btn btn-outline-primary btn-sm me-1"
                                title="Publish mading"
                                onClick={() => publishMading(singleMading.id)}
                                disabled={kelasPublish}
                              >
                                <FontAwesomeIcon icon={faUpload} />
                              </button>
                              <button
                                className="btn btn-outline-primary btn-sm"
                                title="Delete mading"
                                data-bs-toggle="modal"
                                data-bs-target="#hapusMading"
                                onClick={() => getSingleMading(singleMading)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </div>

                            {/* Modalnya delete gallery */}
                            <ModalDeleteItem
                              body="Apakah benar ingin hapus mading ini?"
                              id="hapusMading"
                              onClick={() => hapusMading(madingSingle.id)}
                            />
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
