import http from "./httpService";

const apiUrl = process.env.REACT_APP_APIURL + "/value";

export function getAllValue() {
  return http.get(apiUrl);
}

export function deleteValue(id, data) {
  return http.delete(apiUrl + "/delete/" + id, data);
}

export function updateValue(id, data, headers) {
  return http.put(apiUrl + "/update/" + id, data, headers);
}

export function postValue(data, headers) {
  return http.post(apiUrl, data, headers);
}
