import http from "./httpService";

const apiUrl = process.env.REACT_APP_APIURL + "/team";

export function getActiveMembers() {
  return http.get(apiUrl + "/active");
}

export function getInactiveMembers() {
  return http.get(apiUrl + "/inactive");
}

export function getDeletedMembers() {
  return http.get(apiUrl + "/deleted");
}

export function updateTeamMember(id, data, headers) {
  return http.put(apiUrl + "/update/" + id, data, headers);
}

export function postTeamMember(data, headers) {
  return http.post(apiUrl, data, headers);
}

export function aktifkanMember(id, data, headers) {
  return http.put(apiUrl + "/activate/" + id, data, headers);
}

export function nonAktifkanMember(id, data, headers) {
  return http.put(apiUrl + "/inactivate/" + id, data, headers);
}
export function hapusMember(id, data, headers) {
  return http.put(apiUrl + "/delete/" + id, data, headers);
}
