import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import Header from "../Components/Header";
import SidebarMenu from "../Components/SidebarMenu";

export default function NotFound({ userStatus }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!userStatus) return navigate("/login");
  });
  return (
    <div>
      <Header />

      <div className="container-fluid">
        <div className="row vh-100">
          <SidebarMenu />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 my-3">
            <h3>
              Maaf, yg Anda cari tidak ada. Silahkan klik link{" "}
              <Link to="/">ini</Link> untuk kembali ke Homepage.
            </h3>
          </main>
        </div>
      </div>
    </div>
  );
}
