import React, { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import fbConfig from "./Config/firebaseConfig";
import CompanyProfile from "./Pages/CompanyProfile";
import Gallery from "./Pages/Gallery";
import GalleryImagesTambah from "./Pages/GalleryImagesTambah";
import GalleryTambah from "./Pages/GalleryTambah";
import Kelas from "./Pages/Kelas";
import Layanan from "./Pages/Layanan";
import LayananPreview from "./Pages/LayananPreview";
import LayananTambah from "./Pages/LayananTambah";
import Login from "./Pages/Login";
import Mading from "./Pages/Mading";
import MadingPreview from "./Pages/MadingPreview";
import MadingTambah from "./Pages/MadingTambah";
import Misi from "./Pages/Misi";
import NotFound from "./Pages/NotFound";
import StrukturOrg from "./Pages/StrukturOrg";
import Team from "./Pages/Team";
import Values from "./Pages/Values";
import WebUtilities from "./Pages/WebUtilities";
import Welcome from "./Pages/Welcome";
import MadingKategoriTambah from "./Pages/MadingKategoriTambah";
import Pelatihan from "./Pages/Pelatihan";
import PelatihanTambah from "./Pages/PelatihanTambah";

import "react-toastify/dist/ReactToastify.css";

export default function App() {
  const [userStatus, setUserStatus] = useState(false);
  const [uid, setUid] = useState("");

  const checkUser = () => {
    const auth = fbConfig.auth;
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserStatus(true);
        setUid(user.uid);
      } else {
        setUserStatus(false);
      }
    });
  };

  useEffect(() => {
    checkUser();
  }, []);
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route
          path="/"
          element={<Welcome userStatus={userStatus} uid={uid} />}
        />
        <Route
          path="/login"
          element={<Login userStatus={userStatus} uid={uid} />}
        />

        {/* Route Pelatihan */}
        <Route
          path="/pelatihan"
          element={<Pelatihan userStatus={userStatus} uid={uid} />}
        />
        <Route
          path="/pelatihan/:id"
          element={<PelatihanTambah userStatus={userStatus} uid={uid} />}
        />

        {/* Route Kelas */}
        <Route
          path="/kelas"
          element={<Kelas userStatus={userStatus} uid={uid} />}
        />

        {/* Route Mading */}
        <Route
          path="/mading"
          element={<Mading userStatus={userStatus} uid={uid} />}
        />
        <Route
          path="/mading/preview"
          element={<MadingPreview userStatus={userStatus} uid={uid} />}
        />
        <Route
          path="/mading/tambah"
          element={<MadingTambah userStatus={userStatus} uid={uid} />}
        />
        <Route
          path="/mading/edit/:madingId"
          element={<MadingTambah userStatus={userStatus} uid={uid} />}
        />
        <Route
          path="/mading/kategori/tambah"
          element={<MadingKategoriTambah userStatus={userStatus} uid={uid} />}
        />

        {/* Route Layanan */}
        <Route
          path="/layanan"
          element={<Layanan userStatus={userStatus} uid={uid} />}
        />
        <Route
          path="/layanan/edit/:layananId"
          element={<LayananTambah userStatus={userStatus} uid={uid} />}
        />
        <Route
          path="/layanan/tambah"
          element={<LayananTambah userStatus={userStatus} uid={uid} />}
        />
        <Route
          path="/layanan/preview"
          element={<LayananPreview userStatus={userStatus} />}
        />

        {/* Route Company Profile */}
        <Route
          path="/companyprofiles"
          element={<CompanyProfile userStatus={userStatus} uid={uid} />}
        />
        <Route
          path="/misi"
          element={<Misi userStatus={userStatus} uid={uid} />}
        />
        <Route
          path="/value"
          element={<Values userStatus={userStatus} uid={uid} />}
        />
        <Route
          path="/strukturorg"
          element={<StrukturOrg userStatus={userStatus} uid={uid} />}
        />

        {/* Route Gallery */}
        <Route
          path="/gallery"
          element={<Gallery userStatus={userStatus} uid={uid} />}
        />
        <Route
          path="/gallery/tambah"
          element={<GalleryTambah userStatus={userStatus} uid={uid} />}
        />
        <Route
          path="/gallery/update/:galleryId"
          element={<GalleryTambah userStatus={userStatus} uid={uid} />}
        />
        <Route
          path="/gallery/:galleryId/tambah/images"
          element={<GalleryImagesTambah userStatus={userStatus} uid={uid} />}
        />

        {/* Route Lain2 */}
        <Route
          path="/team"
          element={<Team userStatus={userStatus} uid={uid} />}
        />
        <Route
          path="/webutils"
          element={<WebUtilities userStatus={userStatus} uid={uid} />}
        />
        <Route
          path="*"
          element={<NotFound userStatus={userStatus} uid={uid} />}
        />
      </Routes>
    </>
  );
}
