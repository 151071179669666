import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { faArrowLeft, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Header from "../Components/Header";
import SidebarMenu from "../Components/SidebarMenu";
import "../Styles/MadingPreview.css";

export default function MadingPreview({ userStatus }) {
  const { state } = useLocation();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (!userStatus) return navigate("/login");
  }, [userStatus, navigate]);
  return (
    <div>
      <Header />

      <div className="container-fluid">
        <div className="row vh-100">
          <SidebarMenu />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 my-3">
            <div className="text-end my-2">
              <button
                className="btn btn-outline-primary btn-sm me-1"
                onClick={goBack}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <Link to={"/mading/edit/" + state.id} state={state}>
                <button className="btn btn-outline-primary btn-sm">
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              </Link>
            </div>
            <h3 className="my-3 text-center">{state.title}</h3>
            <div className="containerImageMadingPreview">
              <img src={state.image} className="Cover rounded-4" alt="BTC" />
            </div>
            <div className="p-3">
              <p className="text-muted">Penulis: {state.author}</p>
              <p dangerouslySetInnerHTML={{ __html: state.content }}></p>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
