import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faPencil,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../Components/Header";
import SidebarMenu from "../Components/SidebarMenu";
import EditorInputForm from "../Components/EditorInputForm";
import ModalDeleteItem from "../Components/ModalDeleteItem";
import {
  deleteSingleMisi,
  getallMisi,
  postMisi,
  updateMisi,
} from "../services/misiService";

export default function Misi({ userStatus, uid }) {
  const [misis, setMisis] = useState([]);
  const [misi, setMisi] = useState({
    id: 0,
    misi: "",
  });
  const navigate = useNavigate();

  const getMisis = async () => {
    const { data } = await getallMisi();
    setMisis(data.results);
  };

  const getSingleMisi = (misi) => {
    setMisi(misi);
  };

  const handleChange = (e, id) => {
    setMisi({ id, misi: e.target.getContent() });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataAPI = { misi: misi.misi };
    const headers = { headers: { "x-auth-token": uid } };

    if (misi.id !== 0) {
      await updateMisi(misi.id, dataAPI, headers);
      toast("Data sudah dirubah");
      setMisi({ id: 0, misi: "" });
      getMisis();
    } else {
      await postMisi(dataAPI, headers);
      toast("Data sudah ditambahkan");
      setMisi({ id: 0, misi: "" });
      getMisis();
    }
  };

  const deleteMisi = async (id) => {
    const data = { headers: { "x-auth-token": uid } };

    await deleteSingleMisi(id, data);
    setMisi({ id: 0, misi: "" });
    toast.error("Data sudah dihapus");
    getMisis();
  };

  const reset = (e) => {
    e.preventDefault();
    setMisi({ id: 0, misi: "" });
  };

  useEffect(() => {
    if (!userStatus) return navigate("/login");
    getMisis();
  }, [userStatus, navigate]);
  return (
    <div>
      <Header />

      <div className="container-fluid">
        <div className="row vh-100">
          <SidebarMenu />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className=" pt-3 pb-2 mb-3 border-bottom">
              <h3>Misi Perusahaan</h3>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th className="text-center">Misi</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {misis.length === 0 ? (
                      <tr>
                        <td colSpan={3} className="text-center">
                          Silahkan menambahkan misi perusahaan
                        </td>
                      </tr>
                    ) : (
                      misis.map((mission, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}.</td>
                            <td
                              dangerouslySetInnerHTML={{ __html: mission.misi }}
                            ></td>
                            <td>
                              <div className="btn-group">
                                <button
                                  className="btn btn-outline-primary btn-sm"
                                  onClick={() => getSingleMisi(mission)}
                                >
                                  <FontAwesomeIcon icon={faPencil} />
                                </button>
                                <button
                                  className="btn btn-outline-primary btn-sm"
                                  onClick={() => getSingleMisi(mission)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteItem"
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </div>
                              <ModalDeleteItem
                                id="deleteItem"
                                body="Yakin ingin menghapus misi ini?"
                                onClick={() => deleteMisi(misi.id)}
                              />
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className=" pt-3 pb-2 mb-3 border-bottom">
              <form onSubmit={handleSubmit}>
                <div className="float-end">
                  <button className="btn btn-sm btn-primary me-1">
                    <FontAwesomeIcon icon={faSave} />
                  </button>
                  <button className="btn btn-sm btn-primary" onClick={reset}>
                    <FontAwesomeIcon icon={faArrowsRotate} />
                  </button>
                </div>
                <EditorInputForm
                  id="misi"
                  kelasContainerEditor="inputWebUtil"
                  label="Tambah Misi Perusahaan"
                  value={misi.misi}
                  onChange={(e) => handleChange(e, misi.id)}
                />
              </form>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
