import http from "./httpService";

const apiUrl = process.env.REACT_APP_APIURL + "/webutil";

export function getWebUtilities() {
  return http.get(apiUrl);
}

export function updateWebUtilities(id, data, headers) {
  return http.put(apiUrl + "/update/" + id, data, headers);
}

export function postWebUtilities(data, headers) {
  return http.post(apiUrl, data, headers);
}
