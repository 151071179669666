import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble, faSave } from "@fortawesome/free-solid-svg-icons";

import Header from "../Components/Header";
import SidebarMenu from "../Components/SidebarMenu";
import data from "../Config/dataConfig";
import UploadImageForm from "../Components/UploadImageForm";
import fbConfig from "../Config/firebaseConfig";
import { getSingleGaleri, postGaleriImage } from "../services/galleryService";

export default function GalleryImagesTambah({ userStatus, uid }) {
  const { galleryId } = useParams();
  const [image, setImage] = useState(data.fotoDefault);
  const [gallery, setGallery] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  const getGallery = async () => {
    const { data } = await getSingleGaleri(galleryId);
    setGallery(data.result[0]);
  };

  const handleSelectedImage = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleUploadImage = (e) => {
    e.preventDefault();
    const storageRef = ref(fbConfig.storage, "gallery/" + selectedImage.name);
    uploadBytes(storageRef, selectedImage).then(() => {
      getDownloadURL(
        ref(fbConfig.storage, "gallery/" + selectedImage.name)
      ).then((url) => setImage(url));
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const headers = { headers: { "x-auth-token": uid } };
    const dataAPI = {
      image: image,
    };
    await postGaleriImage(galleryId, dataAPI, headers);
    getGallery();
  };

  const goToGallery = (e) => {
    e.preventDefault();
    navigate("/gallery");
  };

  useEffect(() => {
    if (!userStatus) return navigate("/login");
    getGallery();
    // eslint-disable-next-line
  }, [userStatus, navigate]);
  return (
    <div>
      <Header />

      <div className="container-fluid">
        <div className="row vh-100">
          <SidebarMenu />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="row py-lg-5 text-center border-bottom">
              <div className="col-lg-6 col-md-8 mx-auto">
                <p
                  className="lead"
                  dangerouslySetInnerHTML={{ __html: gallery.caption }}
                ></p>
                <p className="fw-light">
                  {moment(gallery.date).format("DD/MM/YYYY")}
                </p>
                <p className="fw-light">{gallery.location}</p>
              </div>
            </div>
            <div className="py-3 border-bottom">
              <form onSubmit={handleSubmit}>
                <button className="btn btn-primary btn-sm float-end my-1">
                  <FontAwesomeIcon icon={faSave} />
                </button>
                <button
                  className="btn btn-primary btn-sm float-end my-1 me-1"
                  onClick={goToGallery}
                >
                  <FontAwesomeIcon icon={faCheckDouble} />
                </button>
                <UploadImageForm
                  id="image"
                  label="Upload Image Gallery"
                  onChange={handleSelectedImage}
                  onClick={handleUploadImage}
                  buttonLabel="Upload"
                  kelasContainerImage="containerComProfileImage"
                  kelasImage="comProfileImage"
                  image={image}
                />
              </form>
            </div>
            <div className="py-3 row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3 justify-content-center">
              {gallery.GalleryImages === undefined ? (
                <p className="text-center">loading...</p>
              ) : gallery.GalleryImages.length === 0 ? (
                <p className="text-center">
                  Belum ada gambarnya nih. Upload donk!
                </p>
              ) : (
                gallery.GalleryImages.map((image) => {
                  return (
                    <div className="col" key={image.id}>
                      <img
                        className="photoStrukOrg"
                        src={image.image}
                        alt="BTC"
                      />
                    </div>
                  );
                })
              )}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
