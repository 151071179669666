import React, { Component } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import data from "../Config/dataConfig";
import EditorInputForm from "../Components/EditorInputForm";
import InputForm from "../Components/InputForm";
import UploadImageForm from "../Components/UploadImageForm";
import fbConfig from "../Config/firebaseConfig";
import {
  getProfile,
  postProfile,
  updateProfile,
} from "../services/profileService";

export default class MainCompProfile extends Component {
  state = {
    company_profile: {
      company_name: "nama perusahaan",
      logo1: data.fotoDefault,
      logo2: data.fotoDefault,
      vision: "visi",
      motto: "motto",
      about_company: "tentang perusahaan",
      instagram: "instagram",
      youtube: "youtube",
      twitter: "twitter",
      officePicture: data.fotoDefault,
      address_room: "alamat ruangan",
      address_building: "alamat gedung",
      latitude: "latitude",
      longitude: "longitude",
      email: "email",
      phone: "telphone",
    },
    selectedImage: null,
    cekProfile: [],
  };

  getCompanyProfile = async () => {
    const { data } = await getProfile();
    const company_profile = data.result[0];
    const cekProfile = data.result;
    this.setState({ company_profile, cekProfile });
  };

  componentDidMount = () => {
    this.getCompanyProfile();
  };

  handleChange = (e) => {
    this.setState({
      company_profile: {
        ...this.state.company_profile,
        [e.target.id]: e.target.value,
      },
    });
  };

  handleEditorChange = (e) => {
    this.setState({
      company_profile: {
        ...this.state.company_profile,
        [e.target.id]: e.target.getContent(),
      },
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      company_name,
      logo1,
      logo2,
      vision,
      motto,
      about_company,
      instagram,
      youtube,
      twitter,
      officePicture,
      address_room,
      address_building,
      latitude,
      longitude,
      email,
      phone,
    } = this.state.company_profile;
    const dataAPI = {
      company_name: company_name,
      logo1: logo1,
      logo2: logo2,
      vision: vision,
      motto: motto,
      about_company: about_company,
      instagram: instagram,
      youtube: youtube,
      twitter: twitter,
      officePicture: officePicture,
      address_room: address_room,
      address_building: address_building,
      latitude: latitude,
      longitude: longitude,
      email: email,
      phone: phone,
    };
    const headers = { headers: { "x-auth-token": this.props.uid } };

    if (this.state.cekProfile.length !== 0) {
      await updateProfile(this.state.company_profile.id, dataAPI, headers);
      toast("Data sudah dirubah");
      this.props.navigate("/");
    } else {
      await postProfile(dataAPI, headers);
      toast("Data baru sudah ditambahkan");
      this.props.navigate("/");
    }
  };

  goBack = (e) => {
    e.preventDefault();
    this.props.navigate(-1);
  };

  handleImageSelected = (e) => {
    if (e.target.files[0]) {
      this.setState({
        selectedImage: e.target.files[0],
      });
    }
  };

  uploadImage = (e, id) => {
    e.preventDefault();
    const storageRef = ref(
      fbConfig.storage,
      "company_profile/" + this.state.selectedImage.name
    );
    uploadBytes(storageRef, this.state.selectedImage).then(() => {
      getDownloadURL(
        ref(
          fbConfig.storage,
          "company_profile/" + this.state.selectedImage.name
        )
      ).then((url) => {
        this.setState({
          company_profile: {
            ...this.state.company_profile,
            [id]: url,
          },
        });
      });
    });
  };

  render() {
    const { company_profile } = this.state;
    return (
      <div className=" pt-3 pb-2 mb-3 border-bottom">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h3>Company Profile</h3>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="btn-group me-2">
              <button
                className="btn btn-outline-primary me-1"
                onClick={this.goBack}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
            </div>
          </div>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <InputForm
                label="Nama Perusahaan"
                type="text"
                id="company_name"
                value={company_profile.company_name}
                onChange={this.handleChange}
              />
              <InputForm
                label="Alamat Ruangan"
                type="text"
                id="address_room"
                value={company_profile.address_room}
                onChange={this.handleChange}
              />
              <InputForm
                label="Alamat Gedung"
                type="text"
                id="address_building"
                value={company_profile.address_building}
                onChange={this.handleChange}
              />
              <div className="row">
                <div className="col-md-6">
                  <InputForm
                    label="Latitude"
                    type="text"
                    id="latitude"
                    value={company_profile.latitude}
                    onChange={this.handleChange}
                  />
                  <InputForm
                    label="Longitude"
                    type="text"
                    id="longitude"
                    value={company_profile.longitude}
                    onChange={this.handleChange}
                  />
                  <InputForm
                    label="Email"
                    type="text"
                    id="email"
                    value={company_profile.email}
                    onChange={this.handleChange}
                  />
                  <InputForm
                    label="Phone"
                    type="text"
                    id="phone"
                    value={company_profile.phone}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <InputForm
                    label="Instagram"
                    type="text"
                    id="instagram"
                    value={company_profile.instagram}
                    onChange={this.handleChange}
                  />
                  <InputForm
                    label="Youtube"
                    type="text"
                    id="youtube"
                    value={company_profile.youtube}
                    onChange={this.handleChange}
                  />
                  <InputForm
                    label="Twitter"
                    type="text"
                    id="twitter"
                    value={company_profile.twitter}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <UploadImageForm
                label="Logo 1"
                id="logo1"
                onChange={this.handleImageSelected}
                onClick={(e) => this.uploadImage(e, "logo1")}
                buttonLabel="Upload"
                kelasContainerImage="containerComProfileImage"
                kelasImage="comProfileImage"
                image={company_profile.logo1}
              />
              <UploadImageForm
                label="Logo 2"
                id="logo2"
                onChange={this.handleImageSelected}
                onClick={(e) => this.uploadImage(e, "logo2")}
                buttonLabel="Upload"
                kelasContainerImage="containerComProfileImage"
                kelasImage="comProfileImage"
                image={company_profile.logo2}
              />
              <UploadImageForm
                label="Gambar Kantor"
                id="officePicture"
                onChange={this.handleImageSelected}
                onClick={(e) => this.uploadImage(e, "officePicture")}
                buttonLabel="Upload"
                kelasContainerImage="containerComProfileImage"
                kelasImage="comProfileImage"
                image={company_profile.officePicture}
              />
            </div>
            <div className="col-md-6">
              <EditorInputForm
                id="vision"
                kelasContainerEditor="inputComProfile1"
                label="Visi Perusahaan"
                value={company_profile.vision}
                onChange={this.handleEditorChange}
              />
              <EditorInputForm
                id="motto"
                kelasContainerEditor="inputComProfile1"
                label="Motto Perusahaan"
                value={company_profile.motto}
                onChange={this.handleEditorChange}
              />
              <EditorInputForm
                id="about_company"
                kelasContainerEditor="inputComProfile2"
                label="Tentang Perusahaan"
                value={company_profile.about_company}
                onChange={this.handleEditorChange}
              />
              <div className="float-end">
                <button
                  className="btn btn-outline-primary me-1"
                  onClick={this.goBack}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button className="btn btn-primary">
                  <FontAwesomeIcon icon={faSave} />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
