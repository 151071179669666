import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../Components/Header";
import SidebarMenu from "../Components/SidebarMenu";
import "../Styles/StrukturOrg.css";
import ValuesInput from "../MainPages/ValuesInput";
import ModalDeleteItem from "../Components/ModalDeleteItem";
import { deleteValue, getAllValue } from "../services/valueService";

export default function Values({ userStatus, uid }) {
  const [values, setValues] = useState([]);
  const [editvalue, setEditValue] = useState({});
  const [valueId, setValueId] = useState(0);
  const navigate = useNavigate();

  const getValues = async () => {
    const { data } = await getAllValue();
    setValues(data.results);
  };

  const rubahValue = (value) => {
    setEditValue(value);
    window.scrollTo(0, 0);
  };

  const hapusValue = async (id) => {
    const data = { headers: { "x-auth-token": uid } };

    await deleteValue(id, data);
    setEditValue({ id: 0, value: "", image: data.fotoDefault });
    toast("Data sudah dihapus");
    getValues();
  };

  useEffect(() => {
    if (!userStatus) return navigate("/login");
    getValues();
  }, [userStatus, navigate]);
  return (
    <div>
      <Header />

      <div className="container-fluid">
        <div className="row vh-100">
          <SidebarMenu />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="pt-3 pb-2 mb-3 border-bottom">
              <ValuesInput
                editvalue={editvalue}
                whenSubmit={getValues}
                uid={uid}
              />
            </div>
            <div className="table-responsive">
              <h3>Core Values Brilliant</h3>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th className="text-center">Gambar</th>
                    <th className="text-center">Value</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {values.map((value, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}.</td>
                        <td>
                          <img
                            src={value.image}
                            className="photoStrukOrg"
                            alt="BTC"
                          />
                        </td>
                        <td>{value.value}</td>
                        <td>
                          <div className="btn-group">
                            <button
                              className="btn btn-outline-primary btn-sm"
                              onClick={() => rubahValue(value)}
                            >
                              <FontAwesomeIcon icon={faPencil} />
                            </button>
                            <button
                              className="btn btn-outline-primary btn-sm"
                              onClick={() => setValueId(value.id)}
                              data-bs-toggle="modal"
                              data-bs-target="#hapusValue"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </div>
                          <ModalDeleteItem
                            id="hapusValue"
                            body="Apakah benar ingin hapus value?"
                            onClick={() => hapusValue(valueId)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
