import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import EditorInputForm from "../Components/EditorInputForm";
import InputForm from "../Components/InputForm";
import {
  getSingleGaleri,
  postGaleri,
  updateGaleri,
} from "../services/galleryService";

export default class MainGalleryTambah extends Component {
  state = {
    id: 0,
    title: "",
    caption: "",
    date: "",
    location: "",
  };

  componentDidMount = () => {
    if (this.props.url === "update") {
      this.getSingleGallery();
    }
  };

  getSingleGallery = async () => {
    const { data } = await getSingleGaleri(this.props.galleryId);
    this.setState({
      id: data.result[0].id,
      title: data.result[0].title,
      caption: data.result[0].caption,
      location: data.result[0].location,
      date: data.result[0].date.substring(0, 10),
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleEditorChange = (e) => {
    this.setState({
      caption: e.target.getContent(),
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { id, date, title, location, caption } = this.state;
    const dataAPI = {
      date: date,
      title: title,
      location: location,
      caption: caption,
    };
    const headers = { headers: { "x-auth-token": this.props.uid } };

    if (id !== 0) {
      await updateGaleri(id, dataAPI, headers);
      toast("Data sudah dirubah");
      this.props.navigate("/gallery");
    } else {
      const { data } = await postGaleri(dataAPI, headers);
      toast("Data sudah ditambahkan");
      this.props.navigate("/gallery/" + data.result.id + "/tambah/images");
    }
  };

  goBack = (e) => {
    e.preventDefault();
    this.props.navigate(-1);
  };

  render() {
    const { caption, date, location, title } = this.state;
    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h3>Tambah Gallery</h3>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="btn-toolbar mb-2 mb-md-0 float-end">
            <button
              className="btn btn-outline-primary btn-sm me-1"
              onClick={this.goBack}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <button className="btn btn-primary btn-sm">
              <FontAwesomeIcon icon={faSave} />
            </button>
          </div>
          <InputForm
            label="Judul"
            type="text"
            id="title"
            value={title}
            onChange={this.handleChange}
          />
          <InputForm
            label="Tanggal (MM/DD/YYYY)"
            type="date"
            id="date"
            value={date}
            onChange={this.handleChange}
          />
          <InputForm
            label="Lokasi"
            type="text"
            id="location"
            value={location}
            onChange={this.handleChange}
          />
          <EditorInputForm
            id="caption"
            kelasContainerEditor="inputComProfile2"
            label="Caption"
            value={caption}
            onChange={this.handleEditorChange}
          />
        </form>
      </div>
    );
  }
}
