import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../Components/Header";
import MainCompProfile from "../MainPages/MainCompProfile";
import SidebarMenu from "../Components/SidebarMenu";
import "../Styles/CompanyProfile.css";

export default function CompanyProfile({ userStatus, uid }) {
  const navigate = useNavigate();
  useEffect(() => {
    if (!userStatus) return navigate("/login");
  });
  return (
    <div>
      <Header />

      <div className="container-fluid">
        <div className="row vh-100">
          <SidebarMenu />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <MainCompProfile navigate={navigate} uid={uid} />
          </main>
        </div>
      </div>
    </div>
  );
}
