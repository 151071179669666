import http from "./httpService";

const apiUrl = process.env.REACT_APP_APIURL + "/layanan";

export function getAllLayanans() {
  return http.get(apiUrl);
}

export function postLayanan(data, headers) {
  return http.post(apiUrl, data, headers);
}

export function updateLayanan(id, data, headers) {
  return http.put(apiUrl + "/update/" + id, data, headers);
}

export function deleteSingleLayanan(id, data, headers) {
  return http.put(apiUrl + "/delete/" + id, data, headers);
}
