import React, { Component } from "react";
import { faArrowsRotate, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { toast } from "react-toastify";

import data from "../Config/dataConfig";
import fbConfig from "../Config/firebaseConfig";
import InputForm from "../Components/InputForm";
import UploadImageForm from "../Components/UploadImageForm";
import { postMembers, updateMembers } from "../services/strukturOrgService";

export default class StrukOrgInput extends Component {
  state = {
    id: 0,
    name: "",
    photo: data.fotoDefault,
    jabatan: "",
    selectedImage: null,
  };

  componentDidUpdate = (prevProps) => {
    if (Object.keys(this.props.editMember).length === 0) {
      return null;
    }
    if (this.props.editMember.id !== prevProps.editMember.id) {
      this.setState({
        id: this.props.editMember.id,
        name: this.props.editMember.name,
        photo: this.props.editMember.photo,
        jabatan: this.props.editMember.jabatan,
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let dataAPI = {
      name: this.state.name,
      photo: this.state.photo,
      jabatan: this.state.jabatan,
    };
    const headers = { headers: { "x-auth-token": this.props.uid } };

    if (this.state.id !== 0) {
      await updateMembers(this.props.editMember.id, dataAPI, headers);
      toast("Data sudah dirubah");
      this.setState({ id: 0, name: "", photo: data.fotoDefault, jabatan: "" });
      this.props.whenSubmit();
    } else {
      await postMembers(dataAPI, headers);
      toast("Data sudah ditambahkan");
      this.setState({ id: 0, name: "", photo: data.fotoDefault, jabatan: "" });
      this.props.whenSubmit();
    }
  };

  handleImageSelected = (e) => {
    this.setState({
      selectedImage: e.target.files[0],
    });
  };

  uploadImage = (e, id) => {
    e.preventDefault();
    const storageRef = ref(
      fbConfig.storage,
      "struktur_organisasi/" + this.state.selectedImage.name
    );
    uploadBytes(storageRef, this.state.selectedImage).then(() => {
      getDownloadURL(
        ref(
          fbConfig.storage,
          "struktur_organisasi/" + this.state.selectedImage.name
        )
      ).then((url) =>
        this.setState({
          [id]: url,
        })
      );
    });
  };

  kosongkan = (e) => {
    e.preventDefault();
    this.setState({
      name: "",
      photo: data.fotoDefault,
      jabatan: "",
    });
  };
  render() {
    const { name, photo, jabatan } = this.state;
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className="d-flex justify-content-between">
            <h5>Tambah/Edit Struktur Organisasi</h5>

            <div className="text-end">
              <button className="btn btn-primary btn-sm me-1">
                <FontAwesomeIcon icon={faSave} />
              </button>
              <button
                className="btn btn-primary btn-sm"
                onClick={this.kosongkan}
              >
                <FontAwesomeIcon icon={faArrowsRotate} />
              </button>
            </div>
          </div>
          <InputForm
            label="Nama"
            type="text"
            id="name"
            value={name}
            onChange={this.handleChange}
          />
          <InputForm
            label="Jabatan"
            type="text"
            id="jabatan"
            value={jabatan}
            onChange={this.handleChange}
          />
          <UploadImageForm
            id="photo"
            label="Foto"
            onChange={this.handleImageSelected}
            onClick={(e) => this.uploadImage(e, "photo")}
            buttonLabel="Upload"
            kelasContainerImage="containerComProfileImage"
            kelasImage="comProfileImage"
            image={photo}
          />
        </form>
      </div>
    );
  }
}
