import React from "react";

export default function UploadImageForm({
  id,
  label,
  onChange,
  onClick,
  buttonLabel,
  kelasContainerImage,
  kelasImage,
  image,
}) {
  return (
    <div className="mb-3">
      <label htmlFor="gambar" className="form-label">
        {label}
      </label>
      <input
        type="file"
        className="form-control mb-1"
        onChange={onChange}
        id={id}
      />
      <div className="row">
        <div className="col-2">
          <button onClick={onClick} className="btn btn-outline-primary">
            {buttonLabel}
          </button>
        </div>
        <div className="col-5">
          <div className={kelasContainerImage}>
            <img className={kelasImage} src={image} alt="BTC" />
          </div>
        </div>
      </div>
    </div>
  );
}
