import http from "./httpService";

const apiUrlMading = process.env.REACT_APP_APIURL + "/mading";
const apiUrlKategoriMading = process.env.REACT_APP_APIURL + "/madingkategori";

export function getAllMadings() {
  return http.get(apiUrlMading);
}

export function deleteSingleMading(id, data, headers) {
  return http.put(apiUrlMading + "/delete/" + id, data, headers);
}

export function publishSingleMading(id, data, headers) {
  return http.put(apiUrlMading + "/publish/" + id, data, headers);
}

export function postMading(data, headers) {
  return http.post(apiUrlMading, data, headers);
}

export function updateMading(id, data, headers) {
  return http.put(apiUrlMading + "/update/" + id, data, headers);
}

// Kategori Mading

export function getMadingCategory() {
  return http.get(apiUrlKategoriMading + "/active");
}

export function postMadingCategory(data, headers) {
  return http.post(apiUrlKategoriMading, data, headers);
}
