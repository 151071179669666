import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import EditorInputForm from "../Components/EditorInputForm";
import {
  getWebUtilities,
  postWebUtilities,
  updateWebUtilities,
} from "../services/webUtilsService";

export default class MainWebUtils extends Component {
  state = {
    Welcome_Title: "title",
    Welcome_Subtitle: "subtitle",
    Welcome_Motto: "motto",
    utilities: [],
  };

  getWebUtils = async () => {
    const { data } = await getWebUtilities();
    if (data.result.length === 0) return;
    const utilities = data.result;
    const Welcome_Title = data.result[0].Welcome_Title;
    const Welcome_Subtitle = data.result[0].Welcome_Subtitle;
    const Welcome_Motto = data.result[0].Welcome_Motto;
    this.setState({
      utilities,
      Welcome_Title,
      Welcome_Subtitle,
      Welcome_Motto,
    });
  };

  componentDidMount = () => {
    this.getWebUtils();
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.getContent(),
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let dataAPI = {
      Welcome_Title: this.state.Welcome_Title,
      Welcome_Subtitle: this.state.Welcome_Subtitle,
      Welcome_Motto: this.state.Welcome_Motto,
    };
    const headers = { headers: { "x-auth-token": this.props.uid } };

    if (this.state.utilities.length !== 0) {
      await updateWebUtilities(this.state.utilities[0].id, dataAPI, headers);
      toast("Data sudah diupdate");
      this.props.navigate("/");
    } else {
      await postWebUtilities(dataAPI, headers);
      toast("Data sudah ditambahkan");
      this.props.navigate("/");
    }
  };

  goBack = (e) => {
    e.preventDefault();
    this.props.navigate(-1);
  };

  render() {
    const { Welcome_Title, Welcome_Subtitle, Welcome_Motto } = this.state;
    return (
      <div className=" pt-3 pb-2 mb-3 border-bottom">
        <h3>Web Utilization</h3>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <EditorInputForm
                id="Welcome_Title"
                kelasContainerEditor="inputWebUtil"
                label="Welcome Title"
                value={Welcome_Title}
                onChange={this.handleChange}
              />
              <EditorInputForm
                id="Welcome_Subtitle"
                kelasContainerEditor="inputWebUtil"
                label="Welcome Subtitle"
                value={Welcome_Subtitle}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-6">
              <EditorInputForm
                id="Welcome_Motto"
                kelasContainerEditor="inputWebUtil"
                label="Welcome Motto"
                value={Welcome_Motto}
                onChange={this.handleChange}
              />
              <div className="float-end">
                <button
                  className="btn btn-outline-primary me-1"
                  onClick={this.goBack}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button className="btn btn-primary">
                  <FontAwesomeIcon icon={faSave} />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
