import React, { useState } from "react";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import Header from "../Components/Header";
import InputForm from "../Components/InputForm";
import SidebarMenu from "../Components/SidebarMenu";
import { postMadingCategory } from "../services/madingService";

export default function MadingKategoriTambah({ uid }) {
  const [kategori, setKategori] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setKategori(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataAPI = { kategori: kategori };
    const headers = { headers: { "x-auth-token": uid } };

    await postMadingCategory(dataAPI, headers);
    toast("Kategori Mading sudah ditambahkan");
    navigate("/mading/tambah");
  };

  const goBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  return (
    <div>
      <Header />

      <div className="container-fluid">
        <div className="row vh-100">
          <SidebarMenu />
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 my-3">
            <h3>Tambah Kategori</h3>
            <form onSubmit={handleSubmit}>
              <InputForm
                label="Kategori"
                type="text"
                id="kategori"
                value={kategori}
                onChange={handleChange}
              />
              <div className="text-end">
                <button
                  className="btn btn-primary btn-sm me-1"
                  onClick={goBack}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button className="btn btn-primary btn-sm">
                  <FontAwesomeIcon icon={faSave} />
                </button>
              </div>
            </form>
          </main>
        </div>
      </div>
    </div>
  );
}
