import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Header from "../Components/Header";
import SidebarMenu from "../Components/SidebarMenu";
import MainGalleryTambah from "../MainPages/MainGalleryTambah";

export default function GalleryTambah({ userStatus, uid }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { galleryId } = useParams();

  const urlUpdate = pathname.split("/");

  useEffect(() => {
    if (!userStatus) return navigate("/login");
  });
  return (
    <div>
      <Header />

      <div className="container-fluid">
        <div className="row vh-100">
          <SidebarMenu />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <MainGalleryTambah
              navigate={navigate}
              url={urlUpdate[2]}
              galleryId={galleryId}
              uid={uid}
            />
          </main>
        </div>
      </div>
    </div>
  );
}
