import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";

import "../Styles/Header.css";
import data from "../Config/dataConfig";
import fbConfig from "../Config/firebaseConfig";
import { getLogo } from "../services/headerService";

export default function Header() {
  const [logo, setLogo] = useState({});

  useEffect(() => {
    const getCompanyProfile = async () => {
      const { data } = await getLogo();
      setLogo(data.result[0]);
    };
    getCompanyProfile();
  }, []);

  const SignOut = () => {
    const auth = fbConfig.auth;
    signOut(auth);
  };

  let Logo = "";
  Logo = logo === undefined ? data.fotoDefault : logo.logo2;
  return (
    <header className="navbar navbar-dark sticky-top bg-light flex-md-nowrap p-0 shadow">
      <Link to="/" className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6">
        <div className="containerLogoHeader">
          <img src={Logo} alt="BTC logo" />
        </div>
      </Link>
      <button
        className="navbar-toggler position-absolute d-md-none collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="navbar-nav">
        <div className="nav-item text-nowrap">
          <button className="btn nav-link px-3 text-body" onClick={SignOut}>
            Sign out
          </button>
        </div>
      </div>
    </header>
  );
}
