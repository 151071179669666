import React, { Component } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faSave } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import data from "../Config/dataConfig";
import fbConfig from "../Config/firebaseConfig";
import InputForm from "../Components/InputForm";
import UploadImageForm from "../Components/UploadImageForm";
import { postValue, updateValue } from "../services/valueService";

export default class ValuesInput extends Component {
  state = {
    id: 0,
    value: "",
    image: data.fotoDefault,
    selectedImage: null,
  };

  componentDidUpdate(prevProps) {
    if (Object.keys(this.props.editvalue).length === 0) {
      return null;
    }
    if (this.props.editvalue.id !== prevProps.editvalue.id) {
      this.setState({
        id: this.props.editvalue.id,
        value: this.props.editvalue.value,
        image: this.props.editvalue.image,
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  handleSelectedImage = (e) => {
    this.setState({
      selectedImage: e.target.files[0],
    });
  };

  uploadImage = (e) => {
    e.preventDefault();

    const storageRef = ref(
      fbConfig.storage,
      "values/" + this.state.selectedImage.name
    );

    uploadBytes(storageRef, this.state.selectedImage).then(() => {
      getDownloadURL(
        ref(fbConfig.storage, "values/" + this.state.selectedImage.name)
      ).then((url) =>
        this.setState({
          image: url,
        })
      );
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const dataAPI = {
      value: this.state.value,
      image: this.state.image,
    };
    const headers = { headers: { "x-auth-token": this.props.uid } };

    if (this.state.id !== 0) {
      await updateValue(this.props.editvalue.id, dataAPI, headers);
      toast("Data sudah dirubah");
      this.setState({ id: 0, value: "", image: data.fotoDefault });
      this.props.whenSubmit();
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      await postValue(dataAPI, headers);
      toast("Data sudah ditambahkan");
      this.setState({ id: 0, value: "", image: data.fotoDefault });
      this.props.whenSubmit();
      window.scrollTo(0, document.body.scrollHeight);
    }
  };

  reset = (e) => {
    e.preventDefault();
    this.setState({
      id: 0,
      value: "",
      image: data.fotoDefault,
      selectedImage: null,
    });
  };
  render() {
    const { value, image } = this.state;
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className="d-flex justify-content-between">
            <h5>Rubah/Tambah Core Value</h5>
            <div className="d-flex">
              <button className="btn btn-primary btn-sm me-1">
                <FontAwesomeIcon icon={faSave} />
              </button>
              <button className="btn btn-primary btn-sm" onClick={this.reset}>
                <FontAwesomeIcon icon={faArrowsRotate} />
              </button>
            </div>
          </div>
          <InputForm
            label="Core Value"
            type="text"
            id="value"
            value={value}
            onChange={this.handleChange}
          />
          <UploadImageForm
            id="image"
            label="Gambar Value"
            onChange={this.handleSelectedImage}
            onClick={this.uploadImage}
            buttonLabel="Upload"
            kelasContainerImage="containerComProfileImage"
            kelasImage="comProfileImage"
            image={image}
          />
        </form>
      </div>
    );
  }
}
