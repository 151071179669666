import http from "./httpService";

const apiUrl = process.env.REACT_APP_APIURL + "/companyprofile";

export function getProfile() {
  return http.get(apiUrl);
}

export function updateProfile(id, data, headers) {
  return http.put(apiUrl + "/update/" + id, data, headers);
}

export function postProfile(data, headers) {
  return http.post(apiUrl, data, headers);
}
