import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowsRotate,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { toast } from "react-toastify";

import data from "../Config/dataConfig";
import fbConfig from "../Config/firebaseConfig";
import InputForm from "../Components/InputForm";
import UploadImageForm from "../Components/UploadImageForm";
import EditorInputForm from "../Components/EditorInputForm";
import { postLayanan, updateLayanan } from "../services/layananService";

export default class MainLayananTambah extends Component {
  state = {
    id: 0,
    title: "",
    title2: "",
    picture: data.fotoDefault,
    subTitle: "",
    description: "",
    selectedImage: null,
  };

  handleChange = (e) => {
    this.setState({
      title: e.target.value,
      title2: e.target.value.toLowerCase().split(" ").join("-"),
    });
  };

  handleEditorChange = (e) => {
    this.setState({
      [e.target.id]: e.target.getContent(),
    });
  };

  handleSelectedImage = (e) => {
    this.setState({
      selectedImage: e.target.files[0],
    });
  };

  handleUploadImage = (e) => {
    e.preventDefault();
    const storageRef = ref(
      fbConfig.storage,
      "layanan/" + this.state.selectedImage.name
    );
    uploadBytes(storageRef, this.state.selectedImage).then(() => {
      getDownloadURL(
        ref(fbConfig.storage, "layanan/" + this.state.selectedImage.name)
      ).then((url) =>
        this.setState({
          picture: url,
        })
      );
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { id, title, title2, picture, subTitle, description } = this.state;
    const dataAPI = {
      title: title,
      title2: title2,
      picture: picture,
      subTitle: subTitle,
      description: description,
    };
    const headers = { headers: { "x-auth-token": this.props.uid } };

    if (id === 0) {
      await postLayanan(dataAPI, headers);
      toast("Data sudah ditambahkan");
      this.props.navigate("/layanan");
    } else {
      await updateLayanan(id, dataAPI, headers);
      toast("Data sudah dirubah");
      this.props.navigate("/layanan");
    }
  };

  reset = (e) => {
    e.preventDefault();
    this.setState({
      title: "",
      title2: "",
      picture: data.fotoDefault,
      subTitle: "",
      description: "",
    });
  };

  goBack = (e) => {
    e.preventDefault();
    this.props.navigate(-1);
  };

  componentDidMount = () => {
    const { location } = this.props;
    if (location.pathname.split("/")[2] === "edit") {
      this.setState({
        id: location.state.id,
        title: location.state.title,
        title2: location.state.title2,
        picture: location.state.picture,
        subTitle: location.state.subTitle,
        description: location.state.description,
      });
    }
  };
  render() {
    const { title, picture, subTitle, description } = this.state;
    return (
      <div>
        <h3>Tambah/Edit Layanan</h3>
        <div className="formTambahLayanan py-3">
          <form onSubmit={this.handleSubmit}>
            <div className="text-end">
              <button
                className="btn btn-outline-primary btn-sm me-1"
                onClick={this.goBack}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <button
                className="btn btn-outline-primary btn-sm me-1"
                onClick={this.reset}
              >
                <FontAwesomeIcon icon={faArrowsRotate} />
              </button>
              <button className="btn btn-primary btn-sm me-1">
                <FontAwesomeIcon icon={faSave} />
              </button>
            </div>
            <div className="row">
              <div className="col-md-6">
                <InputForm
                  label="Judul"
                  type="text"
                  id="title"
                  value={title}
                  onChange={this.handleChange}
                />
                <UploadImageForm
                  id="picture"
                  label="Cover"
                  onChange={this.handleSelectedImage}
                  onClick={this.handleUploadImage}
                  buttonLabel="Upload"
                  kelasContainerImage="containerComProfileImage"
                  kelasImage="comProfileImage"
                  image={picture}
                />
              </div>
              <div className="col-md-6">
                <EditorInputForm
                  id="subTitle"
                  kelasContainerEditor="inputComProfile1"
                  label="Sub-Judul"
                  value={subTitle}
                  onChange={this.handleEditorChange}
                />
              </div>
            </div>
            <EditorInputForm
              id="description"
              kelasContainerEditor="inputComProfile2"
              label="Deskripsi"
              value={description}
              onChange={this.handleEditorChange}
            />
          </form>
        </div>
      </div>
    );
  }
}
