import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowsRotate,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { toast } from "react-toastify";

import data from "../Config/dataConfig";
import fbConfig from "../Config/firebaseConfig";
import InputForm from "../Components/InputForm";
import UploadImageForm from "../Components/UploadImageForm";
import EditorInputForm from "../Components/EditorInputForm";
import {
  getMadingCategory,
  postMading,
  updateMading,
} from "../services/madingService";

export default class MainMadingTambah extends Component {
  state = {
    id: 0,
    title: "",
    title2: "",
    image: data.fotoDefault,
    author: "",
    content: "",
    KategoriId: 0,
    kategoris: [],
    selectedImage: null,
  };

  handleChange = (e) => {
    if (e.target.id === "title") {
      this.setState({
        title: e.target.value,
        title2: e.target.value.toLowerCase().split(" ").join("-"),
      });
    } else {
      this.setState({
        [e.target.id]: e.target.value,
      });
    }
  };

  handleEditorChange = (e) => {
    this.setState({
      [e.target.id]: e.target.getContent(),
    });
  };

  handleSelectedImage = (e) => {
    this.setState({
      selectedImage: e.target.files[0],
    });
  };

  handleUploadImage = (e) => {
    e.preventDefault();
    const storageRef = ref(
      fbConfig.storage,
      "mading/" + this.state.selectedImage.name
    );
    uploadBytes(storageRef, this.state.selectedImage).then(() => {
      getDownloadURL(
        ref(fbConfig.storage, "mading/" + this.state.selectedImage.name)
      ).then((url) =>
        this.setState({
          image: url,
        })
      );
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { id, title, title2, image, author, content, KategoriId } =
      this.state;
    const dataAPI = {
      title: title,
      title2: title2,
      image: image,
      author: author,
      content: content,
      KategoriId: parseInt(KategoriId),
    };
    const headers = { headers: { "x-auth-token": this.props.uid } };
    if (id === 0) {
      await postMading(dataAPI, headers);
      toast("Madingnya sudah ditambahkan");
      this.props.navigate("/mading");
    } else {
      await updateMading(id, dataAPI, headers);
      toast("Madingnya sudah dirubah");
      this.props.navigate("/mading");
    }
  };

  reset = (e) => {
    e.preventDefault();
    this.setState({
      title: "",
      title2: "",
      image: data.fotoDefault,
      author: "",
      content: "",
    });
  };

  goBack = (e) => {
    e.preventDefault();
    this.props.navigate(-1);
  };

  getKategoriMadings = async () => {
    const { data } = await getMadingCategory();
    const kategoris = data.results;
    this.setState({ kategoris });
  };

  componentDidMount = () => {
    const { location } = this.props;
    if (location.pathname.split("/")[2] === "edit") {
      this.setState({
        id: location.state.id,
        title: location.state.title,
        title2: location.state.title2,
        image: location.state.image,
        author: location.state.author,
        content: location.state.content,
        KategoriId: location.state.KategoriId,
      });
    }

    // get list of Kategori mading
    this.getKategoriMadings();
  };
  render() {
    const { title, image, author, content, kategoris, KategoriId } = this.state;
    return (
      <div>
        <h3>Tambah/Edit Mading</h3>
        <div className="formTambahMading py-3">
          <form onSubmit={this.handleSubmit}>
            <div className="text-end">
              <button
                className="btn btn-outline-primary btn-sm me-1"
                onClick={this.goBack}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <button
                className="btn btn-outline-primary btn-sm me-1"
                onClick={this.reset}
              >
                <FontAwesomeIcon icon={faArrowsRotate} />
              </button>
              <button className="btn btn-primary btn-sm me-1">
                <FontAwesomeIcon icon={faSave} />
              </button>
            </div>
            <div className="row">
              <div className="col-md-6">
                <InputForm
                  label="Judul"
                  type="text"
                  id="title"
                  value={title}
                  onChange={this.handleChange}
                />
                <div className="mb-3">
                  <label htmlFor="KategoriId" className="form-label">
                    Kategori Mading
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="KategoriId"
                    value={KategoriId}
                    onChange={this.handleChange}
                  >
                    <option>Pilih kategori mading</option>
                    {kategoris.map((kategoriSingle) => {
                      return (
                        <option
                          value={kategoriSingle.id}
                          key={kategoriSingle.id}
                        >
                          {kategoriSingle.kategori}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <InputForm
                  label="Penulis"
                  type="text"
                  id="author"
                  value={author}
                  onChange={this.handleChange}
                />
                <UploadImageForm
                  id="image"
                  label="Cover"
                  onChange={this.handleSelectedImage}
                  onClick={this.handleUploadImage}
                  buttonLabel="Upload"
                  kelasContainerImage="containerComProfileImage"
                  kelasImage="comProfileImage"
                  image={image}
                />
              </div>
              <div className="col-md-6">
                <EditorInputForm
                  id="content"
                  kelasContainerEditor="inputComProfile2"
                  label="Isi Mading"
                  value={content}
                  onChange={this.handleEditorChange}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
