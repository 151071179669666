import http from "./httpService";

const apiUrl = process.env.REACT_APP_APIURL + "/misi";

export function getallMisi() {
  return http.get(apiUrl);
}

export function updateMisi(id, data, headers) {
  return http.put(apiUrl + "/update/" + id, data, headers);
}

export function postMisi(data, headers) {
  return http.post(apiUrl, data, headers);
}

export function deleteSingleMisi(id, data) {
  return http.delete(apiUrl + "/delete/" + id, data);
}
