import http from "./httpService";

const apiUrl = process.env.REACT_APP_APIURL + "/strukturorg";

export function getActiveMembers() {
  return http.get(apiUrl + "/active");
}

export function updateMembers(id, data, headers) {
  return http.put(apiUrl + "/update/" + id, data, headers);
}

export function postMembers(data, headers) {
  return http.post(apiUrl, data, headers);
}

export function deleteMember(id, data, headers) {
  return http.put(apiUrl + "/delete/" + id, data, headers);
}
