import http from "./httpService";

const apiUrlGallery = process.env.REACT_APP_APIURL + "/gallery";
const apiUrlGalleryImage = process.env.REACT_APP_APIURL + "/galleryimage";

// function gallery
export function getAllGaleri() {
  return http.get(apiUrlGallery);
}

export function getSingleGaleri(id) {
  return http.get(apiUrlGallery + "/single/" + id);
}

export function updateGaleri(id, data, headers) {
  return http.put(apiUrlGallery + "/update/" + id, data, headers);
}

export function postGaleri(data, headers) {
  return http.post(apiUrlGallery, data, headers);
}

export function deleteSingleGaleri(id, data) {
  return http.delete(apiUrlGallery + "/delete/" + id, data);
}

// function gallery images

export function postGaleriImage(id, data, headers) {
  return http.post(apiUrlGalleryImage + "/gallery/" + id, data, headers);
}

export function deleteGaleriImage(id, data) {
  return http.delete(apiUrlGalleryImage + "/delete/" + id, data);
}

export function getGaleriImages(id) {
  return http.get(apiUrlGalleryImage + "/gallery/" + id);
}
