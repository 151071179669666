import React, { Component } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Navigate } from "react-router-dom";

import InputForm from "../Components/InputForm";
import fbConfig from "../Config/firebaseConfig";

export default class Login extends Component {
  state = {
    email: "",
    password: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const auth = fbConfig.auth;
    signInWithEmailAndPassword(auth, this.state.email, this.state.password)
      .then(() => console.log("signed in"))
      .catch((err) => {
        const errorCode = err.code;
        const errorMessage = err.errorMessage;

        console.log(errorCode, errorMessage);
      });
  };
  render() {
    if (this.props.userStatus) return <Navigate to="/" />;
    return (
      <div className="loginPage vw-100 vh-100 position-relative">
        <form
          className="w-25 h-40 position-absolute top-50 start-50 translate-middle border border-primary p-3 rounded"
          onSubmit={this.handleSubmit}
        >
          <h5>Login</h5>
          <InputForm
            label="Email"
            type="email"
            id="email"
            onChange={this.handleChange}
          />
          <InputForm
            label="Password"
            type="password"
            id="password"
            onChange={this.handleChange}
          />
          <button className="btn btn-primary">Login</button>
        </form>
      </div>
    );
  }
}
