import React, { Component } from "react";
import { faArrowsRotate, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { toast } from "react-toastify";

import data from "../Config/dataConfig";
import fbConfig from "../Config/firebaseConfig";
import InputForm from "../Components/InputForm";
import UploadImageForm from "../Components/UploadImageForm";
import EditorInputForm from "../Components/EditorInputForm";
import { postTeamMember, updateTeamMember } from "../services/teamService";

export default class TeamInput extends Component {
  state = {
    id: 0,
    name: "",
    experience: "",
    photo: data.fotoDefault,
    selectedImage: null,
  };

  componentDidUpdate = (prevProps) => {
    const { editMember } = this.props;
    if (editMember.id !== prevProps.editMember.id) {
      this.setState({
        id: editMember.id,
        name: editMember.name,
        photo: editMember.photo,
        experience: editMember.experience,
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { id, name, photo, experience } = this.state;
    const dataAPI = {
      name: name,
      photo: photo,
      experience: experience,
    };
    const headers = { headers: { "x-auth-token": this.props.uid } };
    if (id !== 0) {
      await updateTeamMember(id, dataAPI, headers);
      toast("Anggota tim sudah dirubah");
      this.props.whenSubmit();
    } else {
      await postTeamMember(dataAPI, headers);
      toast("Anggota tim sudah ditambah");
      this.props.whenSubmit();
    }

    this.setState({
      id: 0,
      name: "",
      photo: data.fotoDefault,
      experience: "",
    });
  };

  reset = (e) => {
    e.preventDefault();
    this.setState({
      id: 0,
      name: "",
      photo: data.fotoDefault,
      experience: "",
    });
  };

  handleSelectedImage = (e) => {
    this.setState({
      selectedImage: e.target.files[0],
    });
  };

  handleEditorChange = (e) => {
    this.setState({
      [e.target.id]: e.target.getContent(),
    });
  };

  uploadImage = (e) => {
    e.preventDefault();
    const storageRef = ref(
      fbConfig.storage,
      "team/" + this.state.selectedImage.name
    );

    uploadBytes(storageRef, this.state.selectedImage).then(() => {
      getDownloadURL(
        ref(fbConfig.storage, "team/" + this.state.selectedImage.name)
      ).then((url) =>
        this.setState({
          photo: url,
        })
      );
    });
  };
  render() {
    const { experience, name, photo } = this.state;
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <h1>Tambah/Edit Anggota Tim</h1>
          <div className="float-end my-1">
            <button className="btn btn-primary me-1 btn-sm">
              <FontAwesomeIcon icon={faSave} />
            </button>
            <button className="btn btn-primary btn-sm" onClick={this.reset}>
              <FontAwesomeIcon icon={faArrowsRotate} />
            </button>
          </div>
          <InputForm
            label="Nama"
            type="text"
            id="name"
            value={name}
            onChange={this.handleChange}
          />
          <EditorInputForm
            id="experience"
            label="Curriculum Vitae"
            value={experience}
            onChange={this.handleEditorChange}
            kelasContainerEditor="inputComProfile2"
          />
          <UploadImageForm
            id="photo"
            label="Foto Anggota"
            onChange={this.handleSelectedImage}
            onClick={this.uploadImage}
            buttonLabel="Upload"
            kelasContainerImage="containerComProfileImage"
            kelasImage="comProfileImage"
            image={photo}
          />
        </form>
      </div>
    );
  }
}
