import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPlus,
  faPencil,
  faImage,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { toast } from "react-toastify";
import { Link, Navigate } from "react-router-dom";

import Header from "../Components/Header";
import SidebarMenu from "../Components/SidebarMenu";
import data from "../Config/dataConfig";
import ModalDeleteItem from "../Components/ModalDeleteItem";
import {
  deleteGaleriImage,
  deleteSingleGaleri,
  getAllGaleri,
  getGaleriImages,
} from "../services/galleryService";

export default class Gallery extends Component {
  state = {
    galleries: [],
    singleGallery: {},
    galleryImages: [],
    imageDefault: data.fotoDefault,
  };

  getGalleries = async () => {
    const { data } = await getAllGaleri();
    const galleries = data.results;
    this.setState({ galleries });
  };

  componentDidMount = () => {
    this.getGalleries();
  };

  getSingleGallery = (gallery) => {
    this.setState({
      singleGallery: gallery,
      galleryImages: gallery.GalleryImages,
    });
  };

  hapusItem = async (id) => {
    const data = { headers: { "x-auth-token": this.props.uid } };

    await deleteSingleGaleri(id, data);
    toast.error("Galeri sudah dihapus");
    this.getGalleries();
  };

  hapusGambar = async (id) => {
    await deleteGaleriImage(id, {
      headers: { "x-auth-token": this.props.uid },
    });

    const { data } = await getGaleriImages(this.state.singleGallery.id);
    const galleryImages = data.results;
    this.setState({ galleryImages });
  };

  render() {
    const { galleries, singleGallery, imageDefault, galleryImages } =
      this.state;
    if (!this.props.userStatus) return <Navigate to="/login" />;
    return (
      <div>
        <Header />

        <div className="container-fluid">
          <div className="row vh-100">
            <SidebarMenu />

            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h3>Gallery Brilliant</h3>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <Link to="/gallery/tambah">
                    <button className="btn btn-outline-primary btn-sm">
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </Link>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Image</th>
                      <th>Judul</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {galleries.length === 0 ? (
                      <tr>
                        <td colSpan={4} className="text-center">
                          Silahkan upload gallery
                        </td>
                      </tr>
                    ) : (
                      galleries.map((gallery, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {gallery.GalleryImages.length === 0 ? (
                                <img
                                  src={imageDefault}
                                  className="photoStrukOrg"
                                  alt="BTC"
                                />
                              ) : (
                                <img
                                  src={gallery.GalleryImages[0].image}
                                  className="photoStrukOrg"
                                  alt="BTC"
                                />
                              )}
                            </td>
                            <td>{gallery.title}</td>
                            <td>
                              <div className="btn-group">
                                <button
                                  className="btn btn-outline-primary btn-sm"
                                  data-bs-toggle="modal"
                                  data-bs-target="#detilGallery"
                                  onClick={() => this.getSingleGallery(gallery)}
                                  title="Preview"
                                >
                                  <FontAwesomeIcon icon={faEye} />
                                </button>
                                <button
                                  className="btn btn-outline-primary btn-sm"
                                  data-bs-toggle="modal"
                                  data-bs-target="#hapusGallery"
                                  onClick={() => this.getSingleGallery(gallery)}
                                  title="Delete"
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </div>

                              {/* Modalnya detil gallery */}
                              <div
                                className="modal fade"
                                id="detilGallery"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                tabIndex="-1"
                                aria-labelledby="staticBackdropLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center w-100">
                                        <div className="btn-group">
                                          <Link
                                            to={
                                              "/gallery/update/" +
                                              singleGallery.id
                                            }
                                          >
                                            <button
                                              className="btn btn-outline-primary btn-sm me-1"
                                              data-bs-dismiss="modal"
                                              title="Edit"
                                            >
                                              <FontAwesomeIcon
                                                icon={faPencil}
                                              />
                                            </button>
                                          </Link>
                                          <Link
                                            to={
                                              "/gallery/" +
                                              singleGallery.id +
                                              "/tambah/images"
                                            }
                                          >
                                            <button
                                              className="btn btn-outline-primary btn-sm"
                                              data-bs-dismiss="modal"
                                              title="Add Image"
                                            >
                                              <FontAwesomeIcon icon={faImage} />
                                            </button>
                                          </Link>
                                        </div>
                                        <button
                                          type="button"
                                          className="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                    </div>
                                    <div className="modal-body">
                                      <div className="border-bottom">
                                        <p>{singleGallery.location}</p>
                                        <p>
                                          {moment(singleGallery.date).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </p>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: singleGallery.caption,
                                          }}
                                        ></p>
                                      </div>
                                      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 justify-content-center">
                                        {galleryImages ===
                                        undefined ? null : galleryImages.length ===
                                          0 ? (
                                          <p>Belum ada image gallery nih...</p>
                                        ) : (
                                          galleryImages.map((image) => {
                                            return (
                                              <div
                                                className="col d-flex align-items-start"
                                                key={image.id}
                                              >
                                                <img
                                                  className="photoStrukOrg"
                                                  src={image.image}
                                                  alt="BTC"
                                                />
                                                <button
                                                  className="btn btn-outline-primary btn-sm align-baseline"
                                                  onClick={() =>
                                                    this.hapusGambar(image.id)
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faTrash}
                                                  />
                                                </button>
                                              </div>
                                            );
                                          })
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Modalnya delete gallery */}
                              <ModalDeleteItem
                                body="Apakah benar ingin hapus gallery ini?"
                                id="hapusGallery"
                                onClick={() => this.hapusItem(singleGallery.id)}
                              />
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
