import React, { useEffect } from "react";

import Header from "../Components/Header";
import SidebarMenu from "../Components/SidebarMenu";
import WelcomeImg from "../Assets/welcome.jpg";
import { useNavigate } from "react-router-dom";

export default function Welcome({ userStatus }) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!userStatus) return navigate("/login");
  });
  return (
    <div>
      <Header />

      <div className="container-fluid">
        <div className="row vh-100">
          <SidebarMenu />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="position-relative pt-3 pb-2 mb-3 border-bottom h-75">
              <div className="position-absolute top-50 start-50 translate-middle ">
                <img
                  className="bd-placeholder-img card-img-top"
                  width="100%"
                  height="100%"
                  src={WelcomeImg}
                  aria-label="Placeholder: Thumbnail"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                />
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
