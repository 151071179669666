import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPencil,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Header from "../Components/Header";
import SidebarMenu from "../Components/SidebarMenu";
import ModalDeleteItem from "../Components/ModalDeleteItem";
import {
  deleteSingleLayanan,
  getAllLayanans,
} from "../services/layananService";

export default function Layanan({ userStatus, uid }) {
  const [layanans, setLayanans] = useState([]);
  const [layananSingle, setLayananSingle] = useState({});
  const navigate = useNavigate();

  const getLayanans = async () => {
    const { data } = await getAllLayanans();
    setLayanans(data.results);
  };

  const getSingleLayanan = (singleLayanan) => {
    setLayananSingle(singleLayanan);
  };

  const hapusLayanan = async (id) => {
    const data = {};
    const headers = { headers: { "x-auth-token": uid } };

    await deleteSingleLayanan(id, data, headers);
    toast("Data sudah dihapuskan");
    getLayanans();
  };

  useEffect(() => {
    if (!userStatus) return navigate("/login");
    getLayanans();
  }, [userStatus, navigate]);
  return (
    <div>
      <Header />

      <div className="container-fluid">
        <div className="row vh-100">
          <SidebarMenu />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 my-3">
            <div className="d-flex justify-content-between">
              <h3>Layanan Brilliant</h3>
              <Link
                to={{
                  pathname: "/layanan/tambah",
                }}
              >
                <button className="btn btn-outline-primary btn-sm">
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </Link>
            </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Judul</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {layanans.length === 0 ? (
                    <tr>
                      <td colSpan={4} className="text-center">
                        Belum ada layanan yang didaftarkan
                      </td>
                    </tr>
                  ) : (
                    layanans.map((singleLayanan, index) => {
                      let kelasBadge = "badge";
                      kelasBadge +=
                        singleLayanan.status === "deleted"
                          ? " text-bg-danger"
                          : " text-bg-success";
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{singleLayanan.title}</td>
                          <td>
                            <span className={kelasBadge}>
                              {singleLayanan.status}
                            </span>
                          </td>
                          <td>
                            <div className="">
                              <Link to="/layanan/preview" state={singleLayanan}>
                                <button
                                  className="btn btn-outline-primary btn-sm me-1"
                                  title="See real page"
                                >
                                  <FontAwesomeIcon icon={faEye} />
                                </button>
                              </Link>
                              <Link
                                to={"/layanan/edit/" + singleLayanan.id}
                                state={singleLayanan}
                              >
                                <button
                                  className="btn btn-outline-primary btn-sm me-1"
                                  title="Edit item"
                                >
                                  <FontAwesomeIcon icon={faPencil} />
                                </button>
                              </Link>
                              <button
                                className="btn btn-outline-primary btn-sm"
                                title="Hapus item"
                                data-bs-toggle="modal"
                                data-bs-target="#hapusLayanan"
                                onClick={() => getSingleLayanan(singleLayanan)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </div>

                            {/* Modalnya delete gallery */}
                            <ModalDeleteItem
                              body="Apakah benar ingin hapus layanan ini?"
                              id="hapusLayanan"
                              onClick={() => hapusLayanan(layananSingle.id)}
                            />
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
