import http from "./httpService";

const apiUrl = process.env.REACT_APP_APIURL + "/pelatihan";

export function getAllPelatihan() {
  return http.get(apiUrl);
}

export function getSinglePelatihan(id) {
  return http.get(apiUrl + "/single/" + id);
}

export function postPelatihan(data, headers) {
  return http.post(apiUrl, data, headers);
}

export function updatePelatihan(id, data, headers) {
  return http.put(apiUrl + "/update/" + id, data, headers);
}

export function donePelatihan(id, data, headers) {
  return http.put(apiUrl + "/done/" + id, data, headers);
}

export function holdPelatihan(id, data, headers) {
  return http.put(apiUrl + "/hold/" + id, data, headers);
}

export function publishPelatihan(id, data, headers) {
  return http.put(apiUrl + "/publish/" + id, data, headers);
}

export function deletePelatihan(id, data) {
  return http.delete(apiUrl + "/delete/" + id, data);
}
