import React, { useEffect, useState } from "react";

import Header from "../Components/Header";
import SidebarMenu from "../Components/SidebarMenu";
import TeamInput from "../MainPages/TeamInput";
import TeamTabContent from "../MainPages/TeamTabContent";
import { useNavigate } from "react-router-dom";
import {
  getActiveMembers,
  getDeletedMembers,
  getInactiveMembers,
} from "../services/teamService";

export default function Team({ userStatus, uid }) {
  const [editMember, setEditMember] = useState({});
  const [active, setActive] = useState([]);
  const [inactive, setInactive] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const navigate = useNavigate();

  const getEditTeamMember = (member) => {
    setEditMember(member);
    window.scrollTo(0, 0);
  };

  const getAktifMembers = async () => {
    const { data } = await getActiveMembers();
    setActive(data.results);
  };

  const getInaktifMembers = async () => {
    //inactive team members
    const { data } = await getInactiveMembers();
    setInactive(data.results);
  };

  const getMembersHapus = async () => {
    const { data } = await getDeletedMembers();
    setDeleted(data.results);
  };
  const getMembers = () => {
    getAktifMembers();
    getInaktifMembers();
    getMembersHapus();
  };

  useEffect(() => {
    if (!userStatus) return navigate("/login");
    getMembers();
    // eslint-disable-next-line
  }, [userStatus, navigate]);
  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row vh-100">
          <SidebarMenu />
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="pt-3 pb-2 mb-3 border-bottom">
              <TeamInput
                editMember={editMember}
                whenSubmit={getMembers}
                uid={uid}
              />
            </div>
            <TeamTabContent
              whenEditMember={(member) => getEditTeamMember(member)}
              activeMembers={active}
              inactiveMembers={inactive}
              deletedMembers={deleted}
              whenUpdate={getMembers}
              uid={uid}
            />
          </main>
        </div>
      </div>
    </div>
  );
}
