import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MainPelatihanInput from "../MainPages/MainPelatihanInput";

export default function PelatihanTambah({ userStatus, uid }) {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userStatus) return navigate("/login");
  });

  return <MainPelatihanInput navigate={navigate} params={params} uid={uid} />;
}
