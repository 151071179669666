import React, { Component } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowsRotate,
  faSave,
} from "@fortawesome/free-solid-svg-icons";

import fbConfig from "../Config/firebaseConfig";
import data from "../Config/dataConfig";
import PageTemplate from "../Components/pageTemplate";
import InputForm from "../Components/InputForm";
import UploadImageForm from "../Components/UploadImageForm";
import EditorInputForm from "../Components/EditorInputForm";
import {
  getSinglePelatihan,
  postPelatihan,
  updatePelatihan,
} from "../services/pelatihanService";
import { toast } from "react-toastify";

export default class MainPelatihanInput extends Component {
  state = {
    id: 0,
    title: "",
    title2: "",
    brosur: data.fotoDefault,
    date: "",
    caption: "",
    regLink: "",
    selectedImage: null,
  };

  componentDidMount = async () => {
    if (this.props.params.id !== "tambah") {
      const { data } = await getSinglePelatihan(this.props.params.id);
      const pelatihan = data;
      this.setState({
        id: pelatihan.id,
        title: pelatihan.title,
        title2: pelatihan.title2,
        brosur: pelatihan.brosur,
        date: pelatihan.date.substring(0, 10),
        caption: pelatihan.caption,
        regLink: pelatihan.regLink,
      });
    }
  };

  handleChange = (e) => {
    if (e.target.id === "title") {
      this.setState({
        title: e.target.value,
        title2: e.target.value.toLowerCase().split(" ").join("-"),
      });
    } else {
      this.setState({
        [e.target.id]: e.target.value,
      });
    }
  };

  handleSelectedImage = (e) => {
    this.setState({
      selectedImage: e.target.files[0],
    });
  };

  handleUploadImage = (e) => {
    e.preventDefault();
    const storageRef = ref(
      fbConfig.storage,
      "pelatihan/" + this.state.selectedImage.name
    );
    uploadBytes(storageRef, this.state.selectedImage).then(() => {
      getDownloadURL(
        ref(fbConfig.storage, "pelatihan/" + this.state.selectedImage.name)
      ).then((url) =>
        this.setState({
          brosur: url,
        })
      );
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { id, title, title2, brosur, date, caption, regLink } = this.state;
    const dataAPI = {
      title: title,
      title2: title2,
      brosur: brosur,
      date: date,
      caption: caption,
      regLink: regLink,
    };
    const headers = { headers: { "x-auth-token": this.props.uid } };
    if (id === 0) {
      await postPelatihan(dataAPI, headers);
      toast("Program pelatihan sudah ditambahkan");
      this.props.navigate("/pelatihan");
    } else {
      await updatePelatihan(id, dataAPI, headers);
      toast("Program pelatihan sudah dirubah");
      this.props.navigate("/pelatihan");
    }
  };

  handleEditorChange = (e) => {
    this.setState({
      [e.target.id]: e.target.getContent(),
    });
  };

  reset = (e) => {
    e.preventDefault();
    this.setState({
      title: "",
      title2: "",
      brosur: data.fotoDefault,
      date: "",
      caption: "",
      regLink: "",
    });
  };

  goBack = (e) => {
    e.preventDefault();
    this.props.navigate(-1);
  };

  render() {
    const { title, brosur, date, caption, regLink } = this.state;
    return (
      <PageTemplate
        body={
          <>
            <h3>Tambah/Edit Program Pelatihan</h3>
            <div className="formTambahMading py-3">
              <form onSubmit={this.handleSubmit}>
                <div className="text-end">
                  <button
                    className="btn btn-outline-primary btn-sm me-1"
                    onClick={this.goBack}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <button
                    className="btn btn-outline-primary btn-sm me-1"
                    onClick={this.reset}
                  >
                    <FontAwesomeIcon icon={faArrowsRotate} />
                  </button>
                  <button className="btn btn-primary btn-sm me-1">
                    <FontAwesomeIcon icon={faSave} />
                  </button>
                </div>
              </form>
              <div className="row">
                <div className="col">
                  <InputForm
                    label="Judul"
                    type="text"
                    id="title"
                    value={title}
                    onChange={this.handleChange}
                  />
                  <InputForm
                    label="Tanggal (MM/DD/YYYY)"
                    type="date"
                    id="date"
                    value={date}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col">
                  <InputForm
                    label="Link Registrasi"
                    type="text"
                    id="regLink"
                    value={regLink}
                    onChange={this.handleChange}
                  />
                  <UploadImageForm
                    id="brosur"
                    label="Brosur Pelatihan"
                    onChange={this.handleSelectedImage}
                    onClick={this.handleUploadImage}
                    buttonLabel="Upload"
                    kelasContainerImage="containerComProfileImage"
                    kelasImage="comProfileImage"
                    image={brosur}
                  />
                </div>
                <EditorInputForm
                  id="caption"
                  kelasContainerEditor="inputComProfile2"
                  label="Caption Pelatihan"
                  value={caption}
                  onChange={this.handleEditorChange}
                />
              </div>
            </div>
          </>
        }
      />
    );
  }
}
