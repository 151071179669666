import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPencil,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import ModalDeleteItem from "../Components/ModalDeleteItem";
import {
  aktifkanMember,
  hapusMember,
  nonAktifkanMember,
} from "../services/teamService";

export default class TeamTabContent extends Component {
  state = {
    anggota: {},
  };

  getSingleMember = (member) => {
    this.setState({
      anggota: member,
    });
  };

  activateMember = async (id) => {
    const data = {};
    const headers = { headers: { "x-auth-token": this.props.uid } };
    await aktifkanMember(id, data, headers);
    toast("Data sudah dirubah");
    this.props.whenUpdate();
  };

  inactivateMember = async (id) => {
    const data = {};
    const headers = { headers: { "x-auth-token": this.props.uid } };
    await nonAktifkanMember(id, data, headers);
    toast("Data sudah dirubah");
    this.props.whenUpdate();
  };

  deleteMember = async (id) => {
    const data = {};
    const headers = { headers: { "x-auth-token": this.props.uid } };
    await hapusMember(id, data, headers);
    toast("Data sudah dirubah");
    this.props.whenUpdate();
  };

  render() {
    const { activeMembers, inactiveMembers, deletedMembers } = this.props;
    const { anggota } = this.state;
    return (
      <div>
        <h3>Team Brilliant</h3>
        {/* BUTTON TAB */}
        <ul className="nav nav-pills">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="active-tab"
              data-bs-toggle="tab"
              data-bs-target="#active"
              type="button"
            >
              Aktif
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="inactive-tab"
              data-bs-toggle="tab"
              data-bs-target="#inactive"
              type="button"
            >
              Tidak Aktif
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="deleted-tab"
              data-bs-toggle="tab"
              data-bs-target="#deleted"
              type="button"
            >
              Dihapus
            </button>
          </li>
        </ul>

        {/* TAB CONTENT FOR ACTIVE TEAM TAB */}
        <div className="tab-content mb-5">
          <div
            className="tab-pane active"
            id="active"
            role="tabpanel"
            aria-labelledby="active-tab"
          >
            <div className="p-3 table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Photo</th>
                    <th>Nama</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {activeMembers.length === 0 ? (
                    <tr>
                      <td colSpan={4} className="text-center">
                        Belum ada anggota tim yang didaftarkan
                      </td>
                    </tr>
                  ) : (
                    activeMembers.map((member, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              src={member.photo}
                              alt="BTC"
                              className="photoStrukOrg"
                            />
                          </td>
                          <td>{member.name}</td>
                          <td>
                            <div className="btn-group">
                              <button
                                className="btn btn-outline-primary btn-sm"
                                title="edit team"
                                onClick={() =>
                                  this.props.whenEditMember(member)
                                }
                              >
                                <FontAwesomeIcon icon={faPencil} />
                              </button>
                              <button
                                className="btn btn-outline-primary btn-sm"
                                title="inactivate team"
                                data-bs-toggle="modal"
                                data-bs-target="#inactivateTeam1"
                                onClick={() => this.getSingleMember(member)}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </button>
                              <button
                                className="btn btn-outline-primary btn-sm"
                                title="delete team"
                                data-bs-toggle="modal"
                                data-bs-target="#deleteTeam1"
                                onClick={() => this.getSingleMember(member)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </div>
                            <ModalDeleteItem
                              body="Yakin ingin menon-aktifkan anggota tim?"
                              id="inactivateTeam1"
                              onClick={() => this.inactivateMember(anggota.id)}
                            />
                            <ModalDeleteItem
                              body="Yakin ingin menghapus anggota tim?"
                              id="deleteTeam1"
                              onClick={() => this.deleteMember(anggota.id)}
                            />
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* TAB CONTENT FOR INACTIVE TEAM TAB */}
          <div
            className="tab-pane"
            id="inactive"
            role="tabpanel"
            aria-labelledby="inactive-tab"
          >
            <div className="p-3 table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Photo</th>
                    <th>Nama</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {inactiveMembers.length === 0 ? (
                    <tr>
                      <td colSpan={4} className="text-center">
                        Belum ada anggota tim yang didaftarkan
                      </td>
                    </tr>
                  ) : (
                    inactiveMembers.map((member, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              src={member.photo}
                              alt="BTC"
                              className="photoStrukOrg"
                            />
                          </td>
                          <td>{member.name}</td>
                          <td>
                            <div className="btn-group">
                              <button
                                className="btn btn-outline-primary btn-sm"
                                title="edit team"
                                onClick={() =>
                                  this.props.whenEditMember(member)
                                }
                              >
                                <FontAwesomeIcon icon={faPencil} />
                              </button>
                              <button
                                className="btn btn-outline-primary btn-sm"
                                title="activate team"
                                data-bs-toggle="modal"
                                data-bs-target="#activateTeam2"
                                onClick={() => this.getSingleMember(member)}
                              >
                                <FontAwesomeIcon icon={faCheck} />
                              </button>
                              <button
                                className="btn btn-outline-primary btn-sm"
                                title="delete team"
                                data-bs-toggle="modal"
                                data-bs-target="#deleteTeam2"
                                onClick={() => this.getSingleMember(member)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </div>
                            <ModalDeleteItem
                              id="activateTeam2"
                              body="Yakin ingin mengaktifkan anggota tim?"
                              onClick={() => this.activateMember(anggota.id)}
                            />
                            <ModalDeleteItem
                              body="Yakin ingin menghapus anggota tim?"
                              id="deleteTeam2"
                              onClick={() => this.deleteMember(anggota.id)}
                            />
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* TAB CONTENT FOR DELETED TEAM TAB */}
          <div
            className="tab-pane"
            id="deleted"
            role="tabpanel"
            aria-labelledby="deleted-tab"
          >
            <div className="p-3 table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Photo</th>
                    <th>Nama</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {deletedMembers.length === 0 ? (
                    <tr>
                      <td colSpan={4} className="text-center">
                        Belum ada anggota tim yang didaftarkan
                      </td>
                    </tr>
                  ) : (
                    deletedMembers.map((member, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              src={member.photo}
                              alt="BTC"
                              className="photoStrukOrg"
                            />
                          </td>
                          <td>{member.name}</td>
                          <td>
                            <div className="btn-group">
                              <button
                                className="btn btn-outline-primary btn-sm"
                                title="activate team"
                                data-bs-toggle="modal"
                                data-bs-target="#activateTeam3"
                                onClick={() => this.getSingleMember(member)}
                              >
                                <FontAwesomeIcon icon={faCheck} />
                              </button>
                            </div>
                            <ModalDeleteItem
                              id="activateTeam3"
                              body="Yakin ingin mengaktifkan anggota tim?"
                              onClick={() => this.activateMember(anggota.id)}
                            />
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
