import React from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function EditorInputForm({
  id,
  kelasContainerEditor,
  label,
  value,
  onChange,
}) {
  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        {label}
      </label>

      <div className={kelasContainerEditor}>
        <Editor
          id={id}
          apiKey="zgdg3mbew87rbjz1l095t3y714bdknfw57tv9tz7burafepa"
          initialValue={value}
          onChange={onChange}
          init={{
            height: "100%",
            menubar: true,
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "image",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "code",
              "help",
              "wordcount",
            ],
            toolbar:
              "undo redo | blocks | " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
      </div>
    </div>
  );
}
