import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASEAPIKEY,
  authDomain: "brillianttc-aff82.firebaseapp.com",
  projectId: "brillianttc-aff82",
  storageBucket: "brillianttc-aff82.appspot.com",
  messagingSenderId: "7063041041",
  appId: "1:7063041041:web:8ed5080cc6cbbe3ab1adb0",
  measurementId: "G-KT43H1R16Y",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const auth = getAuth(app);
const fbConfig = {
  analytics,
  auth,
  storage,
};

export default fbConfig;
