import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPencil,
  faPlus,
  faPause,
  faCheck,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import PageTemplate from "../Components/pageTemplate";
import {
  deletePelatihan,
  donePelatihan,
  getAllPelatihan,
  holdPelatihan,
  publishPelatihan,
} from "../services/pelatihanService";
import data from "../Config/dataConfig";
import ModalDeleteItem from "../Components/ModalDeleteItem";
import { toast } from "react-toastify";

export default function Pelatihan({ userStatus, uid }) {
  const [pelatihans, setPelatihans] = useState([]);
  const [imageDefault] = useState(data.fotoDefault);
  const [program, setProgram] = useState({});
  const navigate = useNavigate();

  const hapusPelatihan = async (id) => {
    const data = { headers: { "x-auth-token": uid } };
    await deletePelatihan(id, data);
    toast("Data sudah dihapus");
    getPelatihans();
  };

  const pelatihanSelesai = async (id) => {
    const data = {};
    const headers = { headers: { "x-auth-token": uid } };

    await donePelatihan(id, data, headers);
    toast("Data sudah dirubah");
    getPelatihans();
  };

  const tundaPelatihan = async (id) => {
    const data = {};
    const headers = { headers: { "x-auth-token": uid } };

    await holdPelatihan(id, data, headers);
    toast("Data sudah dirubah");
    getPelatihans();
  };

  const tayangkanPelatihan = async (id) => {
    const data = {};
    const headers = { headers: { "x-auth-token": uid } };

    await publishPelatihan(id, data, headers);
    toast("Data sudah dirubah");
    getPelatihans();
  };

  const getProgram = (program) => {
    setProgram(program);
  };

  const getPelatihans = async () => {
    const { data } = await getAllPelatihan();
    setPelatihans(data);
  };

  useEffect(() => {
    if (!userStatus) return navigate("/login");
    getPelatihans();
  }, [userStatus, navigate]);

  return (
    <PageTemplate
      body={
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h3>Program Pelatihan</h3>
            <div className="btn-toolbar mb-2 mb-md-0">
              <Link to="/pelatihan/tambah">
                <button className="btn btn-outline-primary btn-sm">
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </Link>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Brosur</th>
                  <th>Judul</th>
                  <th>Tanggal</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {pelatihans.length === 0 ? (
                  <tr>
                    <td colSpan={5} className="text-center">
                      Punteun, belum ada program pelatihan yang ditambah
                    </td>
                  </tr>
                ) : (
                  pelatihans.map((pelatihan, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {pelatihan.brosur.length === "" ? (
                            <img
                              src={imageDefault}
                              className="photoStrukOrg"
                              alt="BTC"
                            />
                          ) : (
                            <img
                              src={pelatihan.brosur}
                              className="photoStrukOrg"
                              alt="BTC"
                            />
                          )}
                        </td>
                        <td>{pelatihan.title}</td>
                        <td>{moment(pelatihan.date).format("LL")}</td>
                        <td>{pelatihan.status}</td>
                        <td>
                          <div className="btn-group">
                            <button
                              className="btn btn-outline-primary btn-sm"
                              title="edit"
                              onClick={() =>
                                navigate("/pelatihan/" + pelatihan.id)
                              }
                            >
                              <FontAwesomeIcon icon={faPencil} />
                            </button>
                            <button
                              className="btn btn-outline-primary btn-sm"
                              title="Publish"
                              onClick={() => tayangkanPelatihan(pelatihan.id)}
                            >
                              <FontAwesomeIcon icon={faUpload} />
                            </button>
                            <button
                              className="btn btn-outline-primary btn-sm"
                              title="Hold"
                              onClick={() => tundaPelatihan(pelatihan.id)}
                            >
                              <FontAwesomeIcon icon={faPause} />
                            </button>
                            <button
                              className="btn btn-outline-primary btn-sm"
                              title="Done"
                              onClick={() => pelatihanSelesai(pelatihan.id)}
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </button>
                            <button
                              className="btn btn-outline-primary btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#hapusGallery"
                              title="hapus pelatihan"
                              onClick={() => getProgram(pelatihan)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </div>

                          {/* Modalnya delete pelatihan */}
                          <ModalDeleteItem
                            body="Apakah benar ingin hapus pelatihan ini?"
                            id="hapusGallery"
                            onClick={() => hapusPelatihan(program.id)}
                          />
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </>
      }
    />
  );
}
