import React from "react";

export default function ModalDeleteItem({ body, id, onClick }) {
  return (
    <div
      className="modal fade"
      id={id}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">{body}</div>
          <div className="modal-footer">
            <button className="btn btn-primary btn-sm" data-bs-dismiss="modal">
              Batal
            </button>
            <button
              className="btn btn-danger btn-sm"
              data-bs-dismiss="modal"
              onClick={onClick}
            >
              Ya
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
